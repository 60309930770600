class Cms {
  static getEndpoint() {
    return 'https://bauhaus.fp-eauthor.fp-server.com/cms'
  }

  static getToken() {
    return '1d40fd932ae1f0b9a197e1e7f2cdac'
  }

  static async get(args) {
    var response
    var sort = {}
    var filter = {}
    var limit = -1
    if(args.sort !== undefined) {
      sort = args.sort
    }
    if(args.filter !== undefined) {
      filter = args.filter
    }
    if(args.limit !== undefined) {
      limit = args.limit
    }
    if(args.id !== undefined) {
      filter._id = args.id
      response = await fetch(this.getEndpoint() + '/api/collections/get/' + args.type + '?token=' + this.getToken(), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: filter,
          limit: limit
        })
      }).then(res => res.json())
      if(response.entries !== undefined) {
        response = response.entries[0]
      }
    } else {
      response = await fetch(this.getEndpoint() + '/api/collections/get/' + args.type + '?token=' + this.getToken(), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: filter,
          sort: sort,
          limit: limit
        })
      }).then(res => res.json())
      response = response.entries
    }
    return response
  }

  static async getSingleton(args) {
    var response = await fetch(this.getEndpoint() + '/api/singletons/get/' + args.name + '?token=' + this.getToken(), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
    return response
  }

  static getSync(args) {
    var response
    var sort = {}
    var filter = {}
    var limit = -1
    if(args.sort !== undefined) {
      sort = args.sort
    }
    if(args.filter !== undefined) {
      filter = args.filter
    }
    if(args.limit !== undefined) {
      limit = args.limit
    }
    if(args.id !== undefined) {
      filter._id = args.id
      response = fetch(this.getEndpoint() + '/api/collections/get/' + args.type + '?token=' + this.getToken(), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: filter,
          limit: limit
        })
      }).then(res => res.json())
      if(response.entries !== undefined) {
        response = response.entries[0]
      }
    } else {
      response = fetch(this.getEndpoint() + '/api/collections/get/' + args.type + '?token=' + this.getToken(), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: filter,
          sort: sort,
          limit: limit
        })
      }).then(res => res.json())
      response = response.entries
    }
    return response
  }

  static async delete(args) {
    var response
    response = await fetch(this.getEndpoint() + '/api/collections/remove/' + args.type + '?token=' + this.getToken(), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: {
          _id: args.id
        }
      })
    }).then(res => res.json())
  }

  static getImageUrl(args) {
    var width = ''
    if(args.width !== undefined) {
      width = '&w=' + args.width
    }
    var height = ''
    if(args.height !== undefined) {
      height = '&h=' + args.height
    }
    return this.getEndpoint() + '/api/cockpit/image?token=' + this.getToken() + '&src=' + this.getEndpoint() + '/storage/uploads' + args.file + width + height + '&o=1'
  }

  static getDirectUrl(args) {
    return this.getEndpoint() + '/storage/uploads' + args.file
  }

  static async put(args) {
    var data = JSON.parse(args.body);
    data._id = args.id
    var test = await fetch(
      this.getEndpoint() + '/api/collections/save/' + args.type + '?token=' + this.getToken(),
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: data
        })
      }
    )
  }

  static async duplicateWBT(id) {
    var response = await fetch(this.getEndpoint() + '/api/collections/get/wbts', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: {
          _id: id
        },
      })
    }).then(res => res.json())
    response = response.entries[0]
    await fetch(
      this.getEndpoint() + '/api/collections/save/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: response.type,
            title: response.title + ' - Kopie',
            created_by: JSON.parse(sessionStorage.getItem('userId')),
            content: response.content
          }
        })
      }
    )
  }

  static async useTemplate(id) {
    var response = await fetch(this.getEndpoint() + '/api/collections/get/wbts', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: {
          _id: id
        },
      })
    }).then(res => res.json())
    response = response.entries[0]
    await fetch(
      this.getEndpoint() + '/api/collections/save/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: 'wbt',
            title: response.title + ' - Kopie',
            created_by: JSON.parse(sessionStorage.getItem('userId')),
            content: response.content
          }
        })
      }
    )
  }

  static async deleteWBT(id) {
    await fetch(
      this.getEndpoint() + '/api/collections/remove/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: {
            _id: id
          }
        })
      }
    )
  }
}

export default Cms
